import { useCreateLicensePlateCarleadForm } from './useCreateLicensePlateCarleadForm';
const defaultFields = ['licensePlate', 'mileage', 'email'];
export function useLPCarleadForm() {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const LPForm = useCreateLicensePlateCarleadForm([...params, {
    createSteps: [function LPSteps() {
      return [defaultFields];
    }]
  }]);
  return LPForm;
}