import { carleadQuestionnairePartialFlow } from './partialQuestionnaireFlows';
export const defaultCarleadCreationLPFlow = {
  initial: {
    name: 'licensePlate',
    next: ['mileage'],
    nodeType: 'normalField',
    inputFieldType: 'text'
  },
  mileage: {
    name: 'mileage',
    next: ['SELLPOINT'],
    nodeType: 'normalField',
    inputFieldType: 'text'
  },
  email: {
    name: 'email',
    next: ['finish'],
    nodeType: 'normalField',
    inputFieldType: 'text'
  },
  finish: {
    name: 'finish',
    nodeType: 'normalField',
    inputFieldType: 'select',
    next: []
  },
  ...carleadQuestionnairePartialFlow
};