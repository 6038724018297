// import { createBrowserInspector } from '@statelyai/inspect';
import { useConstant } from '@wkda/funnel-components';
import { useActorRef } from '@xstate/react';
import { useCacheStorage } from '../../formCache';
import { useCarleadFieldChangedTracking } from '../../hooks/useCarleadFieldChangedTracking';
import { createFieldCategories, flowToArray, generateConfigForNormalMachine, transformInitialFlow } from '../helpers/parseFlowToMachine';
import { createNormalFlowMachineConfig } from './normalFlowMachineConfig';
import { pushSelectEventToDataLayerAction, questionnaire } from './shared/actions';
import { useSharedMachineServices } from './shared/services';

/**
 * How to debug a machine:
 * - install @statelyai/inspect
 * - uncomment the import of createBrowserInspector
 * - uncomment the inspector variable
 * - add the inspector to the useActorRef call
 * Remember to remove the inspector before committing the code
 */

// const inspector =
//   typeof window !== 'undefined' ? createBrowserInspector() : undefined;

export function useNormalFlowService(_flow) {
  var _flowCategories$dataF, _flowCategories$norma, _flowCategories$quest;
  const flow = transformInitialFlow(_flow);
  const flowArray = flowToArray(_flow);
  const flowCategories = createFieldCategories(flowArray);
  const generatedStatesObjects = generateConfigForNormalMachine(flow, flowArray);
  const cache = useCacheStorage();
  const trackLeadFieldChanged = useCarleadFieldChangedTracking();
  const formMachine = createNormalFlowMachineConfig(generatedStatesObjects);
  const configuredMachine = formMachine.provide({
    actions: {
      // action used for general SELECT action from user
      // and prefill
      pushDataLayerEvent(args) {
        pushSelectEventToDataLayerAction(args, trackLeadFieldChanged);
      },
      pushToDataLayerCache(_, params) {
        trackLeadFieldChanged(params.fieldName, 'cache');
      },
      pushQuestionnaireDataLayerEvent: questionnaire.pushDataLayerEvent(trackLeadFieldChanged),
      pushQuestionToDataLayerCache: questionnaire.pushToDataLayerCache(trackLeadFieldChanged),
      syncQuestionnaireCookies: (args, params) => {
        questionnaire.syncAnswerToCookie(args, params);
      }
    },
    actors: {
      ...useSharedMachineServices()
    }
  });
  const finalMachine = useConstant(() => configuredMachine);
  const actor = useActorRef(finalMachine, {
    // inspect: inspector?.inspect,
    input: {
      dataFields: (_flowCategories$dataF = flowCategories.dataField) !== null && _flowCategories$dataF !== void 0 ? _flowCategories$dataF : [],
      otherFields: (_flowCategories$norma = flowCategories.normalField) !== null && _flowCategories$norma !== void 0 ? _flowCategories$norma : [],
      questionnaireFields: (_flowCategories$quest = flowCategories.questionnaireField) !== null && _flowCategories$quest !== void 0 ? _flowCategories$quest : [],
      // DSB preload support
      // Feed with values object from dsb or empty
      // object if there is none or data does not match
      preloadedData: cache.getData()
    }
  });
  return actor;
}