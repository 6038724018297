import { useQueryClient } from '@tanstack/react-query';
import { formNames } from '@wkda/carlead-creation-form';
import { useModulesStore } from '@wkda/funnel-components';
import { useCarleadConfiguration } from '../../../hooks/useCarleadCreationConfiguration';
import { getFetcherActor } from './getFetcherActor';
export function useSharedMachineServices() {
  const queryClient = useQueryClient();
  const {
    carTypesModule
  } = useModulesStore();
  const {
    fullLocale,
    country
  } = useCarleadConfiguration();
  const languageParams = {
    country,
    locale: fullLocale
  };
  const getFetchActor = getFetcherActor(queryClient, languageParams);
  return {
    fetchManufacturers: getFetchActor([], formNames.manufacturer, carTypesModule.getManufacturers),
    fetchMainTypes: getFetchActor(['manufacturer'], formNames.mainType, carTypesModule.getMainTypes),
    fetchBuiltDates: getFetchActor(['manufacturer', 'mainType'], formNames.builtDate, carTypesModule.getBuiltDates),
    fetchBodyTypes: getFetchActor(['manufacturer', 'mainType', 'builtDate'], formNames.bodyType, carTypesModule.getBodyTypes),
    fetchMainTypeDetails: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType'], formNames.mainTypeDetail, carTypesModule.getMainTypesDetails),
    fetchMainTypeSub: getFetchActor(['manufacturer', 'mainType', 'builtDate', 'bodyType', 'mainTypeDetail'], formNames.mainTypeSub, carTypesModule.getMainTypesSub)
  };
}